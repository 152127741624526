import {BrowserModule} from '@angular/platform-browser'
import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {CustomMaterialsModule} from './custom-materials-module'
import {HttpClientModule} from '@angular/common/http'
import {SpbCommonModule} from './common/common.module'
import {WINDOW_PROVIDERS} from './application/window.provider'
import {LOCAL_STORAGE_PROVIDERS} from './application/localstorage.provider'

import {registerLocaleData} from '@angular/common'
import localeFr from '@angular/common/locales/fr'
import localeFrExtra from '@angular/common/locales/extra/fr'
import {ThemeModule} from '@sparbanken-syd/sparbanken-syd-theme'
import {NgModule} from '@angular/core'

registerLocaleData(localeFr, 'fr', localeFrExtra)


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CustomMaterialsModule,
    HttpClientModule,
    SpbCommonModule,
    ThemeModule
  ],
  providers: [
    WINDOW_PROVIDERS,
    LOCAL_STORAGE_PROVIDERS
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
