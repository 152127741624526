import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {HeaderComponent} from './header/header.component'
import {BreadcrumbsComponent} from './breadcrumbs/breadcrumbs.component'
import {CustomMaterialsModule} from '../custom-materials-module'
import {CommonRoutingModule} from './common-routing.module'
import {FormsModule} from '@angular/forms'
import {BackdropComponent} from './backdrop/backdrop.component'
import {KommunSelectorComponent} from './kommun-selector/kommun-selector.component'

@NgModule({
  declarations: [
    HeaderComponent,
    BreadcrumbsComponent,
    BackdropComponent,
    KommunSelectorComponent,
  ],
  imports: [
    CommonModule,
    CommonRoutingModule,
    CustomMaterialsModule,
    FormsModule,
  ],
  exports: [
    HeaderComponent,
    BreadcrumbsComponent,
    BackdropComponent,
    KommunSelectorComponent,
  ],
})
export class SpbCommonModule {
}
