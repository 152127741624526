// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // loginServiceUrl: 'https://gulapuckar-api-daniel.nytthem.sparbankensyd.se',
  loanServiceUrl: 'https://www-daniel.nytthem.sparbankensyd.se/api',
  hemnetServiceUrl: 'https://hemnet-www-daniel.nytthem.sparbankensyd.se/api',
  commonServiceUrl: 'https://www-daniel.nytthem.sparbankensyd.se/service',
  showDebug: true,
  domain: 'gulapuckar-www-daniel.nytthem.sparbankensyd.se'
}
