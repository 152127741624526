import {Component, EventEmitter, Inject, NgZone, OnDestroy, OnInit, Output} from '@angular/core'
import {Router} from '@angular/router'
import {WINDOW} from '../../application/window.provider'
import {LoanService, SpbConfiguration} from '../../services/loan.service'
import {Subscription} from 'rxjs'
import {LoanPromise, PropertyType, UcDecision} from '../../application/data-types'

@Component({
  selector: 'spb-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  /**
   * A simple primitive to use in the template.
   */
  public isAdmin: boolean

  /**
   * Tells if UC mocking is on or off.
   */
  public mockUC: boolean
  /**
   *
   */
  @Output() application = new EventEmitter()
  /**
   * A subscription to listen to configuration changes. If we are admin we
   * show the menu
   */
  private configSubscription: Subscription

  /**
   * The constructor
   * @param router - Neeeded to navigate on menu actions from code.
   * @param loanService - Get the admin state
   * @param ngZone - The zone is needed for safe navigation.
   * @param injectedWindow - If we navigate of app we need this.
   */
  constructor(
    private router: Router,
    public loanService: LoanService,
    private ngZone: NgZone,
    @Inject(WINDOW) private injectedWindow: Window,
  ) {
  }

  /**
   * Start subscribing to the admin state.
   */
  ngOnInit() {
    this.configSubscription = this.loanService.configState
      .subscribe((configuration: SpbConfiguration) => {
        this.isAdmin = configuration.admin
        this.mockUC = configuration.mockUC
      })
  }

  /**
   * Remember to unsubscribe to avoid leakage.
   */
  ngOnDestroy() {
    this.configSubscription.unsubscribe()
  }

  /**
   * A navigation function that can navigate internally or externally
   *
   * @param link - The place to go, start with https:// to navigate externally.
   */
  public navigate(link: string): void | any {
    if (link.indexOf('https://') !== -1) {
      this.injectedWindow.location.href = link
    } else {
      return this.ngZone.run(() => {
        return this.router.navigate([link])
      })
    }
  }

  /**
   * Reset something, currently supports access token and application.
   * @param item - The item to reset
   */
  resetItem(item: string): void {
    if (item === 'auth') {
      this.loanService.resetToken()
    }

    if (item === 'application') {
      this.loanService.resetApplication()
    }

    if (item === 'personnummer') {
      this.loanService.resetApplicationPersonnummer()
    }
  }

  public preFill(type: number): void {
    const application: LoanPromise = {
      version: 5,
      coApplicant: false,
      applicants: [{
        personNummer: '881118-3238',
        income: 80000,
        occupation: 0,
        contact: {
          name: 'Göran Persson',
          email: 'a.fake@email.com',
          phone: '123456890'
        }
      },
        {
          personNummer: '881118-3238',
          income: 40000,
          occupation: 1,
          contact: {
            name: 'Ingvar Carlsson',
            email: 'b.fake@email.com',
            phone: '2020020202'
          }
        }],
      property: {
        type: PropertyType.VILLA,
        price: 0,
        downPayment: 0
      },
      oldProperty: {
        type: -1,
        fee: 0,
      },
      studyLoan: 1500,
      childrenCount: 1,
      terms: true
    }

    const price = 1000 * 1000
    let income = 100 * 1000
    let personNummer = application.applicants[0].personNummer

    switch (type) {
      case 1:  // Deny kalp
        income = 10000
        break
      case 2:  // Deny UC
        personNummer = '760818-5992'
    }

    application.property = {
      price,
      downPayment: price * 0.15,
      downPaymentPercent: 15,
      type: 0,
      fee: undefined // IF bostadsrätt
    }

    application.applicants[0].uc = {
      applicantName: 'Mister \'Fake\' Hans Persson',
      countyCode: '12',
      municipalityCode: '80',
      parishCode: '01',
      income: ['1', '2'],
      decision: UcDecision.APPROVED,
      reasons: ['Manuellt inmatat resultat'],
      status: 'complete'
    }
    application.applicants[1].uc = {
      applicantName: 'Frau \'Fake\' Maria Medsökande',
      countyCode: '12',
      municipalityCode: '75',
      parishCode: '01',
      income: ['3', '4'],
      decision: UcDecision.APPROVED,
      reasons: ['Manuellt inmatat resultat'],
      status: 'complete'
    }

    application.applicants[0].income = income
    application.applicants[0].personNummer = personNummer
    this.application.emit(application)
  }
}
